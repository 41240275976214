import React from 'react';
import clsx from 'clsx';
import styles from './container.module.scss';
import Img from 'gatsby-image';
import ImageAnimation from '../animate/ImageAnimation';

const ImageAnimationConfig = {
  mass: 20,
  friction: 100,
  tension: 200,
  clamp: true,
};

const SectionImage = ({ className, ...other }) => {
  return (
    <ImageAnimation
      className={styles.ImageAnimation}
      start={{
        transform: `scale(1.2)`,
      }}
      update={{
        transform: `scale(1)`,
        config: ImageAnimationConfig,
      }}
    >
      {/* <SectionImage className={styles.image} fluid={imgHomeWave.childImageSharp.fluid} alt="Die Welle" /> */}
      <Img className={clsx(styles.image, className)} {...other} />
    </ImageAnimation>
  );
};

export default SectionImage;
