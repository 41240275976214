import React from 'react';
import PropTypes from 'prop-types';
import { animated as a, useSpring } from 'react-spring';
import { useInView } from 'react-intersection-observer';

const ImageAnimation = ({ children, start, update, clip, ...otherProps }) => {
  const [spring, setSpring] = useSpring(() => start);
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  setSpring(inView ? update : start);

  return (
    <div
      ref={ref}
      style={{
        overflow: clip ? 'hidden' : 'visible',
      }}
      {...otherProps}
    >
      <a.div style={{ height: '100%', width: '100%', ...spring }}>{children}</a.div>
    </div>
  );
};

ImageAnimation.defaultProps = {
  start: {},
  update: {},
  clip: true,
};

ImageAnimation.propTypes = {
  start: PropTypes.any,
  update: PropTypes.any,
  clip: PropTypes.bool,
};

export default ImageAnimation;
