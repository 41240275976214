import React from 'react';
import SEO from '../components/seo';
import Container from '../components/container/container';
import LayoutContent from '../components/layout/layout-content';
import { graphql, useStaticQuery } from 'gatsby';
import TwoColumnSection from '../components/container/two-column-section';
import SectionImage from '../components/container/section-image';
import TrailAnimation from '../components/animate/TrailAnimation';
import Img from 'gatsby-image';
import styles from '../styles/ueber-uns.module.scss';

import { PageHeading } from '../components/page-heading/page-heading';

const AboutUsPage = () => {
  const {
    imgWho,
    imgHistory,
    portrait: {
      childImageSharp: { fixed: portrait },
    },
  } = useStaticQuery(
    graphql`
      query {
        imgWho: file(relativePath: { eq: "about-us/about_us_who.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 540, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        imgHistory: file(relativePath: { eq: "about-us/about_us_history.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 540, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        portrait: file(relativePath: { eq: "portrait_as.jpg" }) {
          childImageSharp {
            fixed(width: 80, height: 80, quality: 100) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }
      }
    `
  );

  return (
    <LayoutContent>
      <SEO title="Über uns - Evelan GmbH" bodyClass={styles.body} />
      <Container>
        <PageHeading>Über uns</PageHeading>
      </Container>
      <TwoColumnSection variant="fix">
        <div>
          <TrailAnimation variant="SlideUpHeavy">
            <h2>Wer wir sind</h2>
            <p>Evelan GmbH ist ein junges innovatives Unternehmen aus Hamburg.</p>
            <p>
              Wir nutzen die neuesten Webtechnologien und lieben es die Wünsche unserer Kunden individuell umzusetzen,
              tolle Ergebnisse zu erzielen und moderne Webseiten zu kreieren.
            </p>
            <p>
              Dabei sind uns Sicherheit und die super schnelle Ladezeit Ihres Webauftritts sehr wichtig! Das Aufrufen
              einer Webseite soll nicht in Frust enden, sondern zu einem unvergesslichen Erlebnis werden. Verlassen Sie
              sich auf das Know-how und die jahrelange Erfahrung unserer Spezialisten.
            </p>
            <p>
              Anhand Ihrer Anforderungen und Wünsche entwickeln wir Ihre professionelle Webpräsenz, die sich von der
              Konkurrenz deutlich abhebt. Dabei ist es immer das Ziel den Webseitenbesucher als neuen Kunden, Partnern
              oder auch potentiellen Mitarbeiter zu gewinnen.
            </p>
          </TrailAnimation>
        </div>
        <div>
          <SectionImage
            fluid={imgWho.childImageSharp.fluid}
            alt="Modernes Büro mit Computerarbeitsplätzen und Menschen, die sich unterhalten"
          />
        </div>
      </TwoColumnSection>

      <Container className={styles.quote}>
        <TrailAnimation variant="SlideUpHeavy">
          <div className={styles.quoteWrapper}>
            <p className={styles.quoteContent}>
              Ich bin davon überzeugt, dass die innovativen JAMstack Webseiten die mit Hilfe der Cloud Technologien
              umgesetzt werden eben genau das ausmachen: sie sind extrem sicher und blitzschnell! Das lieben unsere
              Kunden und die Suchmaschinen.
            </p>
            <p className={styles.author}>
              <span>
                <strong>Andreas Straub</strong>
                <br />
                Gründer, Evelan GmbH
              </span>
              <Img fixed={portrait} alt="Andreas Straub" className={styles.portrait} width={80} height={80} />
            </p>
          </div>
        </TrailAnimation>
      </Container>

      <TwoColumnSection variant="reverse">
        <div>
          <h2>Unsere Geschichte</h2>
          <p>
            Inhaber und Gründer des Unternehmens Andreas Straub absolvierte vor {new Date().getFullYear() - 2003} Jahren
            das Studienfach “Medieninformatik” und legte damit den Grundstein für die heutige Evelan GmbH.
          </p>
          <p>
            Als Softwareentwickler und Softwarearchitekt entwickelte er sehr früh schon komplexe Webanwendungen für den
            B2B Bereich mit.
          </p>
          <p>
            Die Anforderungen der oft sehr umfangreichen Projekte waren dabei nicht nur auf Funktionalität konzentriert,
            sondern setzten auch bei Sicherheit und Performance, also Schnelligkeit der Webanwendungen, eine hohe
            Priorität. Die Themen Wartbarkeit, Backup, Hochverfügbarkeit, Zuverlässigkeit und Skalierfähigkeit standen
            natürlich gerade bei großen Unternehmen ebenfalls auf der Anforderungsliste, da sie neben Wirtschaftlichkeit
            auch über den Erfolg der erstellten Anwendung entscheiden konnten.
          </p>
          <p>
            Dies war vor der Zeit der großen Public Cloud Anbieter wie Google, Microsoft und Amazon AWS mit immenser
            Manpower verbunden. Nur Unternehmen mit entsprechend großen Budgets konnten sich diese entscheidenden
            Eigenschaften leisten, da die Kosten für die Umsetzung und den Betrieb solch komplexer Webanwendungen
            einfach zu hoch waren.
          </p>
          <p>
            Ende 2017 war es dann so weit, dass die neue Cloud Technologie mit den entsprechenden Software-as-a-Service
            (SaaS) Lösungen neue Möglichkeiten eröffnet hat.
          </p>
          <p>
            Seit Ende 2017 ermöglicht nun eine neue Cloud Technologie mit entsprechenden Software-as-a-Service (SaaS)
            Lösungen, die vermeintlich kleinere Enterprise Webanwendungen bezahlbar machen.
          </p>
          <p>
            Andreas Straub hatte die Idee seine ganze Erfahrung aus der Großindustrie nun auch klein- und
            mittelständischen Unternehmen sowie Selbstständigen zur Verfügung zu stellen. Sein großes Ziel ist dabei
            gleichzeitig das World Wide Web sicherer, attraktiver und schneller zu machen.
          </p>
          <p>
            Er ist davon überzeugt, dass seine innovativen JAMstack Webseiten, die mit Hilfe der Cloud Technologien
            umgesetzt werden, eben genau das ausmachen: sie sind super sicher und ultra schnell!
          </p>
          <p>So entstand die Evelan GmbH, die ursprünglich als Andreas Straub UG gegründet wurde.</p>
        </div>
        <div>
          <SectionImage fluid={imgHistory.childImageSharp.fluid} alt="Geschichte" />
        </div>
      </TwoColumnSection>
    </LayoutContent>
  );
};
export default AboutUsPage;
